import { ReactNode } from 'react';
import { Address, MOPType } from 'types';

export interface Children {
  children: ReactNode;
}

export interface FormikErrors {
  [x: string]: string;
}

export interface IdentifierPayload {
  identifier: MOPType | null;
  type: string;
  mopType: string;
  mop: string;
  accountNumber: string; // for UI use only
  routingNumber: string; // for UI use only
  branchNumber: string; // for UI use only
  digit: string; // for UI use only
}

export const blankMOPPayload: IdentifierPayload = {
  identifier: null,
  type: '',
  mop: '',
  mopType: '',
  accountNumber: '',
  routingNumber: '',
  branchNumber: '',
  digit: '',
};

export interface MemberIdPayload {
  atLeastOne: boolean; // for UI validation only
  emailAddresses: string[];
  identifierPayloads: IdentifierPayload[];
  phoneNumbers: string[];
}

export const blankMemberIdPayload: MemberIdPayload = {
  atLeastOne: false,
  emailAddresses: [],
  identifierPayloads: [],
  phoneNumbers: [],
};
