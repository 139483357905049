export enum TranslationNamespace {
  LEP = 'lepui',
  CONTENTFUL = 'contentful',
}

export enum Languages {
  en = 'en',
  de = 'de',
  all = '*',
}

export enum ObelixBundles {
  LE_FORM_SUBMISSION_CONFIRMATION = 'le_form_submision_confirmation',
  LEP_REQUESTOR_INFORMATION = 'lep_requestor_information',
  LEP_HOME_PAGE_LOGGED_USER = 'lep_home_page_logged_user',
  LEP_MOP_TYPES = 'lep_mop_types',
  LE_OTHER_TYPE_OF_REQUESTS = 'le_othet_type_of_requests',
  DELEGATES = 'Delegates',
  LEP_FORMS_IDENITIFERS = 'lep_forms_identifiers',
  LEP_HOME_PAGE = 'lep_home_page',
  LEP_SUBMIT_NEW_REQUEST = 'lep_submit_new_request',
  MOP_FORM = 'mop_form',
  ALL_FORMS_BUTTONS = 'all_form_buttons',
  LEP_REGISTRATION_FORM_FIELDS = 'lep_registration_form_fields',
  LEP_UNHAPPY_REGISTRATION = 'lep_unhappy_registration',
  LEP_EMERGENCY_FORM = 'lep_emergency_form',
  LEP_NEW_KEYS_102021 = 'lep_portal_NEW_KEYS_102021',
}

export enum ContentfulContent {
  HOME_PAGE = 'lepHomePage',
  EDUCATE_PAGE = 'lepEducatePages',
  ACCOUNT_REGISTRATION = 'lepAccountRegistration',
  ELECTRONIC_SIGNATURE = 'leElectronicSignature',
}
