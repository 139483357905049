export interface Address {
  city: string;
  country: string;
  primary: boolean;
  state: string;
  street1: string;
  street2?: string;
  type: string;
  zipCode: string;
}

export const blankAddress: Address = {
  city: '',
  country: '',
  primary: true,
  state: '',
  street1: '',
  street2: '',
  type: 'business',
  zipCode: '',
};

export interface UserInfoForm {
  agencyAddress: Address;
  agencyName?: string;
  emailAddress: string;
  familyName: string;
  givenName: string;
  phoneNumber?: string;
  title?: string;
}

export const blankUserInfoForm: UserInfoForm = {
  agencyAddress: blankAddress,
  agencyName: '',
  emailAddress: '',
  familyName: '',
  givenName: '',
  phoneNumber: '',
  title: '',
};

export interface RegistrationForm extends UserInfoForm {
  confirmation: boolean;
}

export const blankRegistrationForm: RegistrationForm = {
  ...blankUserInfoForm,
  confirmation: false,
};

export interface User extends UserInfoForm {
  id: string;
  preferredLanguage?: string;
  mostRecentLogin?: Date;
}

export const blankUser: User = {
  ...blankUserInfoForm,
  id: '',
  preferredLanguage: '',
};
