import { MemberIdPayload } from './interfaces';
import { blankMemberIdPayload } from 'types';

export interface OtherForm {
  consentAgreement: boolean;
  consentDate: Date | undefined;
  delegates: string[];
  description: string;
  devices: string[];
  eventDate: Date | undefined;
  investigationCaseNumber: string;
  investigationType: string;
  legalProcess: boolean;
  memberIdPayload: MemberIdPayload;
  needGuidance: boolean;
  preservationRequest: boolean;
  questions: string;
  signature: string;
  signatureAgreement: boolean; // For UI only, not required by the API
  uploadTokens: string[];
}

export const blankOtherForm: OtherForm = {
  legalProcess: false,
  preservationRequest: false,
  needGuidance: false,

  // case information section
  investigationType: '',
  investigationCaseNumber: '',
  description: '',
  devices: [''],
  eventDate: undefined,
  memberIdPayload: blankMemberIdPayload,
  questions: '',

  // consent section
  consentAgreement: false,
  signature: '',
  signatureAgreement: false,
  consentDate: new Date(),

  // plp section
  uploadTokens: [],

  // delegates section
  delegates: [''],
};
