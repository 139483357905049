import { blankMemberIdPayload, MemberIdPayload } from './interfaces';

export interface DeviceForm {
  accountIdNumbers: string[];
  consentAgreement: boolean;
  consentDate: Date | undefined;
  delegates: string[];
  description: string;
  devices: string[];
  esnNumbers: string[];
  eventDate: Date | undefined;
  investigationCaseNumber: string;
  legalProcess: boolean;
  memberIdPayload: MemberIdPayload;
  netflixIdentifier: string;
  signature: string;
  signatureAgreement: boolean; // For UI only, not required by the API
  uploadTokens: string[];
}

export const blankDeviceForm: DeviceForm = {
  legalProcess: false,

  // case information section
  investigationCaseNumber: '',
  netflixIdentifier: '',
  eventDate: undefined,
  devices: [''],
  description: '',
  memberIdPayload: blankMemberIdPayload,

  // consent section
  consentAgreement: false,
  signature: '',
  signatureAgreement: false,
  consentDate: new Date(),

  // plp section
  accountIdNumbers: [''],
  esnNumbers: [''],
  uploadTokens: [],

  // delegates section
  delegates: [''],
};

export const blankDeviceFormWithDevice: DeviceForm = {
  legalProcess: false,

  // case information section
  investigationCaseNumber: '',
  netflixIdentifier: '',
  eventDate: undefined,
  devices: [''],
  description: '',
  memberIdPayload: blankMemberIdPayload,

  // consent section
  consentAgreement: false,
  signature: '',
  signatureAgreement: false,
  consentDate: new Date(),

  // plp section
  accountIdNumbers: [''],
  esnNumbers: [''],
  uploadTokens: [],

  // delegates section
  delegates: [''],
};
