import { blankMemberIdPayload, MemberIdPayload } from './interfaces';
import { blankUserInfoForm, UserInfoForm } from 'types';

export interface EmergencyForm {
  // memberIdentificationType: string;
  emailAddress: string;
  eventDate: Date | undefined;
  description1: string;
  description2: string;
  description3: string;
  description4: string;
  memberIdPayload: MemberIdPayload;
  signatureAgreement: boolean;
  signature: string;
  consentDate: Date | undefined;
}

export interface FullEmergencyForm extends EmergencyForm {
  userInfo: UserInfoForm;
}

export const blankEmergencyForm: EmergencyForm = {
  // memberIdentificationType: '',
  emailAddress: '',
  eventDate: undefined,
  description1: '',
  description2: '',
  description3: '',
  description4: '',
  memberIdPayload: blankMemberIdPayload,
  signatureAgreement: false,
  signature: '',
  consentDate: new Date(),
};

export const blankFullEmergencyForm: FullEmergencyForm = {
  ...blankEmergencyForm,
  userInfo: blankUserInfoForm,
};
