import { blankMOPPayload, IdentifierPayload } from './interfaces';

export interface FraudForm {
  consentAgreement: boolean;
  consentDate: Date | undefined;
  delegates: string[];
  description: string;
  endDate: Date | undefined;
  investigationCaseNumber: string;
  identifierPayloads: IdentifierPayload[];
  signature: string;
  signatureAgreement: boolean; // For UI only, not required by the API
  startDate: Date | undefined;
  uploadTokens: string[];
}

export const blankFraudForm: FraudForm = {
  // case information section
  investigationCaseNumber: '',
  startDate: undefined,
  endDate: new Date(),
  description: '',
  identifierPayloads: [blankMOPPayload],

  // consent section
  consentAgreement: false,
  signature: '',
  signatureAgreement: false,
  consentDate: new Date(),

  // attachments section
  uploadTokens: [],

  // delegates section
  delegates: [''],
};
