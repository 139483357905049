// Here we have our enums, each mapped to an Obelix key
// that we use to display the translated label or value
// in the UI.

export enum TicketStatus {
  NEW = 'ticket_status_submitted',
  PENDING = 'ticket_status_completed',
  OPEN = 'ticket_status_inprogress',
  SOLVED = 'ticket_status_completed',
  CLOSED = 'ticket_status_closed',
}
export type TicketStatusString = keyof typeof TicketStatus;

export enum TicketType {
  MOP = 'ticket_type_mop',
  MOP_PLP = 'ticket_type_mop_plp',
  // EMERGENCY_PENDING is a label we use for analytics only
  EMERGENCY_PENDING = 'ticket_type_emer',
  OTHER_PLP = 'data_request',
  OTHER_PRESERVATION = 'ticket_type_other_preservation',
  OTHER_GUIDANCE = 'ticket_type_other_guidance',
  STOLEN_DEVICE = 'ticket_type_device',
  STOLEN_DEVICE_PLP = 'ticket_type_device_plp',
  // REGISTRATION_REQUEST - not currently used in the UI
}
export type TicketTypeString = keyof typeof TicketType;

export enum MOPTypes {
  CC = 'mop_type_cc',
  EU_DIRECT_DEBIT = 'mop_type_iban',
  US_BA = 'mop_type_bank',
  PAYPAL = 'mop_type_paypal',
  GIFT_CARD = 'mop_type_gift',
  BR_BA = 'mop_type_bank_br',
}
export type MOPTypeString = keyof typeof MOPTypes;

export enum MemberIdentificationTypes {
  EMAIL = 'member_id_option_1',
  MOP = 'member_id_option_2',
}

export enum InvestigationTypes {
  HACKING = 'inv_type_hacking',
  HOMICIDE = 'inv_type_homicide',
  MISSING_PERSON = 'inv_type_missing',
  STOLEN_DEVICE = 'inv_type_device',
  PAYMENT_FRAUD = 'inv_type_fraud',
  OTHER = 'inv_type_other',
}
export type InvestigationTypesString = keyof typeof InvestigationTypes;

export enum PlpInvestigationTypes {
  HACKING = 'inv_type_hacking',
  HOMICIDE = 'inv_type_homicide',
  MISSING_PERSON = 'inv_type_missing',
  OTHER = 'inv_type_other',
}
export type PlpInvestigationTypesString = keyof typeof PlpInvestigationTypes;

export enum CustomFields {
  external_case_number = 'investigation_case_num',
  nature_of_investigation = 'other_nature_investigation',
  date_range_from = 'mop_start_date',
  date_range_to = 'mop_end_date',
  mop_consent = 'ticket_mop_consent',
  stolen_device_consent = 'ticket_device_consent',
}
export type CustomFieldsString = keyof typeof CustomFields;

export enum MOPType {
  CC = 'CC',
  EU_DIRECT_DEBIT = 'EU_DIRECT_DEBIT',
  US_BA = 'US_BA',
  PAYPAL = 'PAYPAL',
  GIFT_CARD = 'GIFT_CARD',
  BR_BA = 'BR_BA',
}

export enum IdentifierType {
  MOP = 'MOP',
  GIFT_CARD = 'GIFT_CARD',
}

export enum RequestType {
  MOP = 'MOP',
  MOP_PLP = 'MOP_PLP',
  STOLEN_DEVICE = 'STOLEN_DEVICE',
  STOLEN_DEVICE_PLP = 'STOLEN_DEVICE_PLP',
  OTHER_PLP = 'OTHER_PLP',
  OTHER_PRESERVATION = 'OTHER_PRESERVATION',
  OTHER_GUIDANCE = 'OTHER_GUIDANCE',
  EMERGENCY = 'EMERGENCY',
  EMERGENCY_PENDING = 'EMERGENCY_PENDING',
}
